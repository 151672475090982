<template>
  <v-card @click="goToDetails" class="w-100">
    <v-card-text class="d-flex flex-row mb-3 w-100">
      <div>
        <v-img
          :src="require('@/assets/svg/needs.svg')"
          width="60"
          height="60"
          contain
        />
      </div>
      <div class="d-flex flex-column mx-3" style="flex-grow:1">
        <span class="font-16 app-bold-font primary-color">
          {{ need.title }}
        </span>
        <div class="d-flex font-12 app-regular-font">
          <div
            class="url-button"
            @click.stop="goToAgencyProfile(need.agency)"
            @mousedown.stop
            @touchstart.stop
          >
            {{ need.agency_name }}
          </div>
        </div>
        <span class="blue-font-color font-12 app-regular-font">
          {{ need.duration }}
        </span>
      </div>
      <div style="flex-shrink: 0" v-if="!hideButtons">
        <v-tooltip bottom v-if="profile._id == need.agency && editable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
              small
              @click.stop="onEdit(need)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                >mdi-pencil</v-icon
              >
            </v-btn>
          </template>
          Edit Need
        </v-tooltip>
        <v-tooltip bottom v-if="profile._id == need.agency">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
              small
              class="ml-2"
              @click.stop="onDelete(need)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                >mdi-delete</v-icon
              >
            </v-btn>
          </template>
          Delete Need
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              small
              style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
              @click.stop="onShare(need)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0A5B8A" size="18">mdi-share</v-icon>
            </v-btn>
          </template>
          Share Need
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text class="mt-0 pt-0 position-relative">
      <span
        class="text-block"
        v-if="text_collapsed"
        v-linkified
        v-text="collapsed_text"
      />
      <span v-else v-text="need.description" class="text-block" v-linkified />
      <span
        class="url-button"
        @click.stop="text_collapsed = false"
        @mousedown.stop
        @touchstart.stop
        v-if="text_collapsed"
        x-small
        style="position: absolute; right: 18px; bottom: 0; background: white"
      >
        ...see more
      </span>
    </v-card-text>
    <v-card-text
      class="mt-0 pt-0 dark-font-color"
      v-if="need.response && profileType == 'Volunteer'"
    >
      <v-divider class="mb-3" />
      Already sent response:
      <span class="app-medium-font">{{ need.response.status }}</span>
    </v-card-text>
    <v-card-text class="mt-0 pt-0 dark-font-color" v-if="need.shifts">
      <v-divider class="mb-3" />
      Shifts:
      <span class="app-medium-font">{{
        need.shifts ? need.shifts.length : 0
      }}</span>

      <span
        class="url-button"
        @click.stop="goToResponse"
        @mousedown.stop
        @touchstart.stop
        v-if="need.responses && need.responses.length"
      >
        / Response:
        <span class="app-medium-font">{{
          need.responses ? need.responses.length : 0
        }}</span>
        <span class="app-medium-font" v-if="getPendingCount">
          (Pending: {{ getPendingCount }})</span
        >
      </span>
    </v-card-text>
    <v-card-text v-if="need.shifts">
      <div v-for="(shift, index) in need.shifts" :key="shift._id">
        <div class="ma-2">
          <span>{{ shift.title ? shift.title : `Shift ${index + 1}` }}</span>
          <span class="ms-4">{{ formatDate(shift.start_date) }}</span>
          <span class="ms-4">{{ shift.hours }} hours</span>
          <span class="ms-4">
            {{
              shift.response
                ? shift.capacity - shift.responses.length
                : shift.capacity
            }}
            slots open
          </span>
          <span class="ms-4">{{ shift.status }}</span>
        </div>
        <v-divider v-if="index" />
      </div>
    </v-card-text>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
    <agency-edit-need
      :item="selected"
      :dialog="editDialog"
      :onCloseDialog="onCloseEditDialog"
      v-if="selected && editDialog"
    />
    <confirm-delete-need-dialog
      :deleteDialog="deleteDialog"
      :need="selected"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onDeletedNeed"
      v-if="selected && deleteDialog"
    />
  </v-card>
</template>
<script>
import AgencyEditNeed from "./create/AgencyEditNeed.vue";
import ConfirmDeleteNeedDialog from "@/components/common/ConfirmDeleteNeedDialog.vue";
import { mapState } from "vuex";
import dateFormat from "dateformat";

export default {
  components: {
    AgencyEditNeed,
    ConfirmDeleteNeedDialog,
  },
  props: {
    need: Object,
    hideButtons: {
      type: Boolean,
      default: false,
    },
    onClicked: Function,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      text_collapsed: true,
      collapsed_text: "",
      editDialog: false,
      deleteDialog: false,
      selected: null,
    };
  },
  methods: {
    goToResponse() {
      this.$router.push({
        name: "agency-responses-feeds",
        query: { _id: this.need._id },
      });
    },
    goToDetails() {
      if (!this.onClicked) {
        this.$router.push({
          name: "agency-need-details",
          query: {
            _id: this.need._id,
          },
        });
      } else {
        this.onClicked(this.need._id);
      }
    },
    goToAgencyProfile(agency) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: agency },
      });
    },
    async onShare() {
      this.copyToClipboard(
        process.env.VUE_APP_WEB_URL + "/needs/details?_id=" + this.need._id
      );
      this.snackbar = true;
      this.snackMessage = "Copied the share url";
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    getCollapsedText() {
      if (!this.need.description) {
        this.text_collapsed = false;
        return;
      }
      const full_text = this.need.description.trim();
      const textArray = full_text.split(/\r\n|\r|\n/);

      // Check for the number of lines
      if (textArray.length > 3) {
        this.collapsed_text = textArray.slice(0, 3).join("\n");
        this.text_collapsed = true;
        return;
      }

      if (full_text.length > 270) {
        this.collapsed_text = full_text.substring(0, 270);
        this.text_collapsed = true;
        return;
      }

      this.collapsed_text = full_text;
      this.text_collapsed = false;
    },
    onEdit(need) {
      this.selected = need;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onDelete(need) {
      this.selected = need;
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
      this.deleteShiftDialog = false;
    },
    onDeletedNeed() {
      this.deleteDialog = false;
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    getPendingCount() {
      let count = 0;
      if (this.need.responses) {
        this.need.responses.map((r) => {
          if (r.status == "Pending") count++;
        });
      }
      return count;
    },
  },
  mounted() {
    this.getCollapsedText();
  },
};
</script>
